@import url(https://fonts.googleapis.com/css?family=Raleway:400,800);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
.cellOuterComment {
    position: relative;
}

.cellComment {
    display: none;
    position: absolute;
    z-index: 100;
    border: 1px;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    padding: 3px;
    color: black;
    top: 20px;
    left: 20px;
    width: 400px;
}

.cellOuterComment:hover span.cellComment {
    display: block;
}

.lightGrey {
    color:lightgray;
}

.orange {
    background-color: rgb(255, 245, 227);
}
/* Import Google Fonts */
li:before {
    font-size: 200%;
}

.just80 {
    max-height: 80%
}

.pointer {
    cursor: pointer;
    color: #23b1c4;
}

.bordered {
    border: 1px solid #B4A4DA !important;
}

.logo {
    max-height: 30px
}

.nowrap {
    white-space: nowrap;
    display: inline-block;
}

.marginText {
    margin: 8px 0;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.right {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    float: right;
}

.left {
    text-align: left;
}

body {
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    font-size: 15px;
    font-size: 15px;
    line-height: 1.5;
    height: 95%;
}

h1, h2, h3, h4, h5 {
    font-family: 'Raleway', sans-serif;
}

b, strong {
    font-weight: 700;
}

b, .ui.segment, p, .ui.list {
    font-size: 15px;
    line-height: 1.75;
}

.bottom_8px {
    position: absolute;
    right: 10px;
    bottom: 16px;
}

table.styled {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    border: 0;
    line-height: 2;
}

tr.striped:nth-child(odd) {
    background-color: #dddddd;
}

tr.border_bottom td {
    border-bottom: 1pt solid #23b1c4;
}

.intextlink {
    cursor: pointer;
}

.error404 {
    font-size: 120px;
    padding: 30px;
}

.size32 {
    font-size: 32pt
}

.size18 {
    font-size: 18pt
}

.overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tr {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.padding8 {
    padding: 8px;
}

.color_medium_light {
    background: #B4A4DA;
}

.padding_top_bottom {
    padding: 10px 0;
}

.padding_left_right {
    padding: 0 10px;
}

.menu_items_group {
    padding: 8px 15px;
    color: rgb(51, 51, 51);
}

@media (max-width: 1000px) {
    .hideContentOnSmallWidth {
        display: none !important;
    }
}

@media (min-width: 1001px) {
    .hideContentOnLargeWidth {
        display: none !important;
    }
}

.ui.secondary.menu .item {
    letter-spacing: 0.28px;
    font-size: 20px !important;
    font-family: 'Playfair Display', serif;
    line-height: 1;
    color: rgb(51, 51, 51) !important;
    font-weight: 600;
}

.ui.secondary.menu .item {
    letter-spacing: 0.28px;
    font-size: 20px;
    font-family: 'Playfair Display', serif;
    line-height: 1;
    color: rgb(51, 51, 51) !important;
    font-weight: 600;
}

.ui.secondary.menu .item:hover {
    font-weight: 400;
}

.ui.secondary.inverted.menu a.item {
    letter-spacing: 0.28px;
    font-size: 20px;
    font-family: 'Playfair Display', serif;
    line-height: 1;
    color: rgb(51, 51, 51) !important;
    /* font-weight: 600; */
}

.ui.menu .ui.dropdown .menu>.item {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-size: 16px !important;
}

.nearCenterScreen {
    position: absolute;
    top: 30%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
